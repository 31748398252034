export const StatusConfig = {
  api: {
    // patch
    user: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    orgUser: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    operator: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    rider: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch -> @key:status -> @val:A/D/I
    organization: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    // patch -> @key:status -> @val:A/D/I
    vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // patch
    fleet: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/`
    },
  },
}
